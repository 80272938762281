$color_white: #ffffff;
$color_black: #111111;
$color_red: #C62828;
$color_yellow: #EBA801;

$color_blue1: #0074AF;
$color_blue2: #0F3448;
$color_dark: #313842;
$color_gray: #F1F1F1;
$color_font: #1B222D;

$color_green: #38BC80;

$max_width: 1250px;
$min_width: 1000px;
$breakpointMid: 990px;

// @media screen and (max-width: $breakpointMid)     

@font-face {
    font-family: "CeraPro";
    src: url("fonts/CeraPro-Light.eot");
    src: url("fonts/CeraPro-Light.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Light.woff") format("woff"), url("fonts/CeraPro-Light.ttf") format("truetype"), url("fonts/CeraPro-Light.svg?#webfont") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "CeraPro";
    src: url("fonts/CeraPro-Regular.eot");
    src: url("fonts/CeraPro-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Regular.woff") format("woff"), url("fonts/CeraPro-Regular.ttf") format("truetype"), url("fonts/CeraPro-Regular.svg?#webfont") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CeraPro";
    src: url("fonts/CeraPro-Medium.eot");
    src: url("fonts/CeraPro-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Medium.woff") format("woff"), url("fonts/CeraPro-Medium.ttf") format("truetype"), url("fonts/CeraPro-Medium.svg?#webfont") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CeraPro";
    src: url("fonts/CeraPro-Bold.eot");
    src: url("fonts/CeraPro-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Bold.woff") format("woff"), url("fonts/CeraPro-Bold.ttf") format("truetype"), url("fonts/CeraPro-Bold.svg?#webfont") format("svg");
    font-weight: 700;
    font-style: normal;
}


%extend-box-shadow-default {
    box-shadow: 0 0 50px rgba(35, 31, 32, .2);
}

%extend-flex-row-between-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

%extend-flex-row-end-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

%extend-flex-column-center-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

%extend-flex-column-end-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

%extend-flex-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

%extend-flex-row-start-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

%extend-flex-column-start-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

%extend-flex-row-start-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

%extend-flex-row-between-start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

%extend-inlineflex-column-center-center {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

%extend-flex-column-center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

%extend-inlineflex-row-start-center {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

%extend-inlineflex-row-center-center {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

%extend-flex-column-start-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

%extend-width-auto {
    width: $max_width;
    margin: 0 auto;
}

%extend-content-absolute-0-100 {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%extend-absolute-0-100 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%extend-fixed-0-100 {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

::selection {
    background: rgba(35, 31, 32, 0.9);
    color: $color_white;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
    background-color: $color_white;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #6B6142;
}

::-webkit-resizer,
::-webkit-scrollbar-corner {
    background-color: $color_white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-family: inherit;
    font-weight: normal;
}

button,
input,
select,
textarea {
    transition: 0.2s;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

html {
    width: 100%;
    min-height: 100%;
    font-family: "CeraPro", Arial, sans-serif;

    @media screen and (max-width: $breakpointMid) {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        overflow-x: hidden;
    }
}

body {
    background: $color_white;
    color: $color_font;
    position: relative;
    resize: none;
    outline: none;
    overflow-x: hidden;
    font-size: 14px;

    background: #CBD2E5;
    background: radial-gradient(at left top, #CBD2E5, #fff1eb, #D5CCE3);

    //background-image: linear-gradient(90deg, #fff1eb, #ace0f9);

    @media screen and (max-width: $breakpointMid) {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        overflow-x: hidden;
        padding-top: 111px;
    }
}

.progress-bar {
    position: fixed;
    left: 0;
    top: 0;
    height: 4px;
    width: 0;
    z-index: 998;
    background: $color_blue1;
}

.desktop {
    display: block;

    @media screen and (max-width: $breakpointMid) {
        display: none;
    }
}

.mobile {
    display: none;

    @media screen and (max-width: $breakpointMid) {
        display: block;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.center {
    max-width: $max_width;
    min-width: $min_width;
    margin: 0 auto;

    @media screen and (max-width: $breakpointMid) {
        width: 100%;
        padding: 0 10px;
    }
}

.min-width {
    width: 1222px;
    margin: 0 auto;

    @media screen and (max-width: $breakpointMid) {
        width: 100%;
    }
}

.margin-top-0px {
    margin-top: 0 !important;
}

.margin-top-10px {
    margin-top: 10px !important;
}

.margin-top-20px {
    margin-top: 20px !important;
}

.margin-top-30px {
    margin-top: 30px !important;
}

.margin-top-40px {
    margin-top: 40px !important;
}

.margin-top-50px {
    margin-top: 50px !important;
}

.margin-bottom-0px {
    margin-bottom: 0 !important;
}

.margin-bottom-10px {
    margin-bottom: 10px !important;
}

.margin-bottom-20px {
    margin-bottom: 20px !important;
}

.margin-bottom-30px {
    margin-bottom: 30px !important;
}

.margin-bottom-40px {
    margin-bottom: 40px !important;
}

.margin-bottom-50px {
    margin-bottom: 50px !important;
}

.margin-right-10px {
    margin-right: 10px !important;
}

.margin-right-20px {
    margin-right: 20px !important;
}

.margin-right-30px {
    margin-right: 30px !important;
}

.margin-right-40px {
    margin-right: 40px !important;
}

.margin-right-50px {
    margin-right: 50px !important;
}

.width-auto {
    width: auto !important;
}

@keyframes opas {
    0% {
        opacity: 0.2;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

.green-dot {
    @extend %extend-flex-row-center-center;
    background-color: #1cbc54;
    $size: 5px;
    width: $size;
    height: $size;
    margin-right: 6px;
    border-radius: 100%;
    animation: opas 1.1s infinite linear;
}

.white-dot {
    @extend %extend-flex-row-center-center;
    background-color: $color_white;
    $size: 5px;
    width: $size;
    height: $size;
    margin-right: 6px;
    border-radius: 100%;
    animation: opas 1.1s infinite linear;
}

.checkbox {
    width: 100%;
    cursor: pointer;
    @extend %extend-flex-row-start-center;
    transition: 0.2s;

    &--checked {}

    .material-symbols-rounded {
        margin-right: 5px;
    }

    &__name {
        display: block;
        width: 100%;
    }

    input[type=checkbox] {
        display: none;
    }
}

// Навигация

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideme {
    0% {
        left: -30px;
        margin-left: 0;
    }

    30% {
        left: 110%;
        margin-left: 80px;
    }

    100% {
        left: 110%;
        margin-left: 80px;
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rotate2 {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

@keyframes bounce-3 {
    100% {
        transform: rotate(0deg);
        opacity: 1;
    }

    0% {
        transform: rotate(-5deg) scale(1.4);
        opacity: 0.2;
    }
}

@keyframes pulse3 {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)
    }

    70% {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 rgba(56, 188, 129, 0.5)
    }

    70% {
        box-shadow: 0 0 0 5px rgba(56, 188, 129, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(56, 188, 129, 0);
    }
}

@keyframes pulse22 {
    0% {
        box-shadow: 0 0 0 0 rgba(56, 188, 129, 0.5);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(56, 188, 129, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(56, 188, 129, 0);
    }
}

@keyframes pulse222 {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 117, 175, 0.5);
    }

    70% {
        box-shadow: 0 0 0 5px rgba(0, 117, 175, .0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 117, 175, .0);
    }
}

@keyframes pulse-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 117, 175, 0.5);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 117, 175, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 117, 175, 0);
    }
}

.material-symbols-rounded--fill {
    font-variation-settings: 'FILL'1, 'wght'400, 'GRAD'0, 'opsz'48;
}

// Шапка
.description {
    font-size: 24px;
    display: block;
    line-height: 1.1;
    font-family: "PorscheNextTT";
    margin-top: 15px;

    @media screen and (max-width: $breakpointMid) {
        font-size: 22px;
        margin-top: 10px;
    }

    strong {
        color: $color_green;
        font-weight: 500;
    }
}

.heading {
    font-size: 40px;
    line-height: 1.1;
    display: block;
    font-weight: 500;
    color: $color_font;

    @media screen and (max-width: $breakpointMid) {
        font-size: 26px;
    }

    strong {
        font-weight: 700;
    }

    &--center {
        text-align: center;
    }

    &__low {
        font-size: 30px;
        @extend %extend-flex-row-center-center;
        line-height: 1.8;

        @media screen and (max-width: $breakpointMid) {
            font-size: 16px;
        }

        .material-symbols-rounded {
            margin-left: 10px;
            margin-right: 5px;
            color: $color_yellow;
        }
    }
}

.button {
    background: #6C6242;
    color: $color_white;
    border: 0;
    @extend %extend-flex-row-center-center;
    padding: 0 20px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    height: 50px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    //animation: pulse-blue 2s infinite;
    border-radius: 50px;

    .material-symbols-rounded {
        margin-left: 5px;
    }

    * {
        position: relative;
        z-index: 100;
    }

    &::after {
        margin-left: -30px;
        content: " ";
        width: 30px;
        height: 300px;
        background: #fff;
        -webkit-animation-delay: 50ms;
        animation-delay: 50ms;
        position: absolute;
        left: -40px;
        top: -150px;
        top-animation-timing-function: ease-in-out;
        transition: all 0.1s;
        -webkit-animation-name: slideme;
        animation-name: slideme;
        -webkit-animation-duration: 3.5s;
        animation-duration: 3.5s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        transform: rotate(45deg);
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    background: url(../images/icons/outline_keyboard_arrow_down_white_24dp.png) right 10px center no-repeat;
    background-size: 20px;
}

.label {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    backdrop-filter: blur(5px);

    &__name {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        padding-left: 20px;
        font-size: 16px;
    }

    &__input {
        display: block;
        width: 100%;
        border: 0;

        background: {
            color: rgba(255, 255, 255, .8);
        }

        height: 55px;
        padding: 0 20px;

        border: 1px rgba(255, 255, 255, .1) solid;
        color: #1a2b38 !important;
        font-weight: 600;

        font: {
            family: inherit;
            size: 16px;
        }

        &::placeholder {
            color: #1a2b38 !important;
            font-weight: 200;
            opacity: .8;
        }

        option {
            color: $color_black;
        }
    }
}

.checkbox {
    width: 100%;
    cursor: pointer;
    @extend %extend-flex-row-start-center;
    transition: 0.2s;

    &--checked {}

    .material-symbols-rounded {
        margin-right: 5px;
    }

    &__name {
        display: block;
        width: 100%;
    }

    input[type=checkbox] {
        display: none;
    }
}

.button-white {
    @extend %extend-flex-row-center-center;
    height: 70px;
    padding: 0 30px;
    cursor: pointer;
    color: $color_white;
    font-weight: 500;
    font-size: 18px;
    transition: .2s;
    background: $color_blue1;
    position: relative;
    overflow: hidden;
    animation: pulse-blue 2s infinite;
    border: 0;
    border: 1px rgba(255, 255, 255, 0.25) solid;

    @media screen and (max-width: $breakpointMid) {
        font-size: 16px;
        height: 60px;
    }

    &:hover {
        background: #006394;

        .material-symbols-rounded {
            transform: translateX(5px);
        }
    }

    * {
        position: relative;
        z-index: 100;
    }

    &::after {
        margin-left: -30px;
        content: " ";
        width: 30px;
        height: 300px;
        background: #fff;
        -webkit-animation-delay: 50ms;
        animation-delay: 50ms;
        position: absolute;
        left: -40px;
        top: -150px;
        top-animation-timing-function: ease-in-out;
        transition: all 0.1s;
        -webkit-animation-name: slideme;
        animation-name: slideme;
        -webkit-animation-duration: 3.5s;
        animation-duration: 3.5s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        transform: rotate(45deg);
    }

    .material-symbols-rounded {
        margin-left: 10px;
        display: block;
        transition: .2s;
    }
}


.nav {
    //background: #231F20;
    //background-color: rgba(17, 25, 40, 0.9);
    color: $color_white;

    backdrop-filter: blur(16px) saturate(180%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 998;

    @media screen and (max-width: $breakpointMid) {
        display: none;
    }

    &__center {
        @extend %extend-flex-row-start-center;
        padding: 0 20px;
        font-size: 13px;

        @media (min-width: 1310px) {
            padding: 0;
            font-size: 14px;
        }
    }

    &__ul {
        @extend %extend-flex-row-between-center;
        list-style: none;
        position: relative;
        width: 100%;
    }

    &__li {
        display: block;
        transition: 0.5s;
        position: relative;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: rgba(255, 255, 255, .2);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s;

            @media screen and (max-width: $breakpointMid) {
                display: none;
            }
        }

        &:hover {
            color: #f1dc97;
        }

        &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
        }

        &--active {
            color: #f1dc97;
            font-weight: bold;

            &::after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: #f1dc97;

                @media screen and (max-width: $breakpointMid) {
                    display: none;
                }
            }
        }

    }

    &__link {
        @extend %extend-flex-row-center-center;
        height: 60px;
        padding: 0 10px;

        @media (min-width: 1310px) {
            padding: 0 20px;
        }
    }
}

.mnav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    display: none;
    color: $color_white;

    backdrop-filter: blur(16px) saturate(180%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

    @media screen and (max-width: $breakpointMid) {
        display: block;
    }

    &__top {
        @extend %extend-flex-row-between-center;

        height: 60px;
        max-height: 60px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        background: $color_white;
        color: $color_black;


        &-logo {
            height: 40px;
            width: auto;
            background: url(../images/logo--lite.png) left top no-repeat;
            background-size: contain;
            margin-left: 10px;
            //font-family: "Font-Bold";
            padding-left: 77px;
            @extend %extend-flex-column-center-start;
            font-size: 11px;
            line-height: 1;
            br {
                display: none
            }

            strong {
                color: #6C6242
            }
        }

        &-block {
            @extend %extend-flex-row-start-center;

            .material-symbols-rounded {
                @extend %extend-flex-row-center-center;
                border-radius: 5px;
                $size: 40px;
                width: $size;
                height: $size;
                margin-left: 5px;
                cursor: pointer;

                &:nth-last-child(1) {
                    margin-right: 10px;
                }
            }
        }
    }

    &__center {
        @extend %extend-flex-row-center-center;
        padding: {
            right: 10px;
            left: 10px;
            bottom: 10px;
        }
        font-size: 12px;
        border-bottom: 1px rgba(255, 255, 255, .1) solid;
        text-align: right;
        justify-content: flex-end;
        align-items: flex-end;

        height: 28px;
        max-height: 28px;
        
        strong {
            display: block;
            margin-left: 5px;
            font-size: 14px;
        }
    }

    &__down {
        padding: 0 10px;
        font-size: 14px;
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        border-bottom: 1px rgba(255, 255, 255, .1) solid;
        //font-family: 'Pro';

        &-item {
            display: block;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &--active {
                color: #F1DC97;
                font-weight: bold;
            }
        }
    }
}

.head {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background: linear-gradient(to right, $color_white, rgba(255, 255, 255, 0.75));

    @media screen and (max-width: $breakpointMid) {
        display: none;
    }

    &__center {
        height: 140px;
        @extend %extend-flex-row-between-center;
        padding: 0 20px;

        @media (min-width: 1310px) {
            padding: 0;
        }
    }

    &__block {
        @extend %extend-flex-row-start-center;
    }

    &__logo {
        width: 130px;
        height: 120px;
        background: url(../images/logo.png) left center no-repeat;
        background-size: contain;
    }

    &__text {
        margin-left: 20px;
        font-size: 12px;

        strong {
            font-size: 15px;
            color: #655B37;
        }
    }

    &__form {
        display: block;
        width: 250px;
        margin-right: 20px;
        position: relative;
        border-bottom: 1px #6C6242 solid;
        transition: .2s;

        &--active {
            width: 350px;
        }   

        .material-symbols-rounded {
            position: absolute;
            @extend %extend-flex-row-center-center;
            width: 24px;
            height: 50px;
            right: 0;
            top: 0;
            cursor: pointer;
            color: #6C6242;
            border: 0;
        } 

        &-input {
            display: block;
            width: calc(100% - 34px);
            border: 0;
            height: 50px;

        }

        &-button {
            &--disabled {
                color: rgba(0, 0, 0, .5) !important;
            }
        }
    }
}

.footer {
    //background: rgba(35, 31, 32, 0.95);
    color: $color_white;

    padding: 30px 0;

    @media (min-width: 1310px) {
        padding: 60px 0;
    }

    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.6);
    border-top: 1px solid rgba(255, 255, 255, .2);
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

    @media screen and (max-width: $breakpointMid) {
        padding: 20px 10px;
    }

    &__center {
        @extend %extend-flex-row-between-start;
        align-items: stretch;
        padding: 0 20px;

        @media (min-width: 1310px) {
            padding: 0;
        }

        @media screen and (max-width: $breakpointMid) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            padding: 0;
        }
    }

    &__block {
        border-radius: 6px;
        padding: 20px;
        backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: calc(100% / 3);
        margin-right: 10px;
        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
            20px 20px 100px rgba(255, 255, 255, .05);

        @media (min-width: 1310px) {
            margin-right: 20px;
        }

        &:nth-last-child(1) {
            margin-right: 0;

            @media screen and (max-width: $breakpointMid) {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            padding: 10px;
        }
    }

    &__heading {
        display: block;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        line-height: 1;
        margin-bottom: 20px;
        padding-bottom: 20px;
        width: 100%;

        @media screen and (max-width: $breakpointMid) {
            padding-top: 10px;
            margin-bottom: 10px;
        }

        &::before {
            $size: 16px;
            background-color: rgba(255, 255, 255, .5);
            width: 3px;
            height: $size;
            content: '';
            position: absolute;
            top: 0;
            left: -21px;

            @media screen and (max-width: $breakpointMid) {
                left: -11px;
                top: 10px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -20px;
            width: calc(100% + 40px);
            height: 1px;
            background: rgba(255, 255, 255, 0.1);

            @media screen and (max-width: $breakpointMid) {
                left: -10px;
                width: calc(100% + 20px);
            }
        }
    }

    .block__list-item {
        border-bottom: 1px rgba(255, 255, 255, .1) solid;

        &:hover {
            .block__list-heading {
                color: inherit;
            }
        }
    }

    &__new {
        @extend %extend-flex-column-center-center;

        &-image {
            width: 100%;
            aspect-ratio: 650/900;
            background: #8C8C8E center no-repeat;
            background-size: contain;
            border-radius: 3px;
            position: relative;
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.5);

            &::before {
                @extend %extend-content-absolute-0-100;
                background: rgba(35, 31, 32, 0.2);
                opacity: 0;
                transition: .2s;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }

        &-name {
            display: block;
            text-align: center;
            width: 100%;
            margin-top: 20px;
            font-size: 16px;

            @media (min-width: 1310px) {
                font-size: 20px;
            }

            @media screen and (max-width: $breakpointMid) {
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }

}

.foot {
    color: $color_white;

    backdrop-filter: blur(16px) saturate(180%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);
    width: 100%;
    padding: 30px 0;
    padding-bottom: 70px;

    @media (min-width: 1310px) {
        padding: 60px 0;
    }

    @media screen and (max-width: $breakpointMid) {
        padding: 20px 10px;
        border-bottom: 0;
    }

    &__center {
        @extend %extend-flex-row-between-start;
        padding: 0 20px;
        
        @media (min-width: 1310px) {
            padding: 0;
        }

        @media screen and (max-width: $breakpointMid) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;
        }
    }

    &__block {
        @extend %extend-flex-column-start-start;

        &--row {
            flex-direction: row !important;
            align-items: center !important;
            justify-content: center !important;

            @media screen and (max-width: $breakpointMid) {
                flex-direction: column !important;
                align-items: flex-start !important;
                justify-content: flex-start !important;
                margin-bottom: 20px;
            }
        }

        &--end {
            align-items: flex-end;

            @media screen and (max-width: $breakpointMid) {
                align-items: flex-start;
            }
        }

        &--contacts {
            line-height: 1.5;
            text-align: right;
            min-width: 40%;

            @media screen and (max-width: $breakpointMid) {
                text-align: left;
                min-width: auto;
            }

            a {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__social {
        @extend %extend-flex-row-start-center;
        margin-top: 10px;

        &-item {
            $size: 50px;
            width: $size;
            height: $size;
            background: center no-repeat;
            background-size: 25px;
            cursor: pointer;
            @extend %extend-flex-row-center-center;

            &--vk {
                background-image: url(../images/icons/vk.png);
            }
            &--telegram {
                background-image: url(../images/icons/telegram.png);
            }
        }
    }

    &__logo {
        display: block;
        width: 121px;
        min-width: 121px;
        height: 71px;
        background: url(../images/logo--white.png) left center no-repeat;
        background-size: contain;
    }

    &__logos {
        @extend %extend-flex-column-start-start;
        margin-left: 20px;
        margin-right: 20px;

        @media screen and (max-width: $breakpointMid) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}

.top {
    //background: rgba(35, 31, 32, 0.95);
    color: $color_white;
    padding: 20px 0;

    @media screen and (max-width: $breakpointMid) {
        padding: 10px 0;
        padding-bottom: 20px;
        border-bottom: 20px $color_white solid;
    }


    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid $color_white;

    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

    &__center {
        @extend %extend-flex-row-between-start;
        padding: 0 20px;

        @media (min-width: 1310px) {
            padding: 0;
        }

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            padding: 0 10px;
        }
    }

    &__item {

        backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 12px;
        padding: 10px;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.1);
        overflow: hidden;
        transition: .2s;

        @media (min-width: 1310px) {
            padding: 20px;
        }

        @media screen and (max-width: $breakpointMid) {
            padding: 0;
            background: none;
            backdrop-filter: none;
            border: 0;
            border-radius: 0;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.35);
        }

        &-image {
            width: 100%;
            background: #8C8C8E center no-repeat;
            background-size: cover;
            //border: 1px #FFFFFF solid;
            border-radius: 6px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            aspect-ratio: 650/900;

            @media screen and (max-width: $breakpointMid) {
                border-radius: 3px;
            }
        }

        &-name {
            display: block;
            text-align: center;
            margin-top: 5px;
            transition: .2s;
            font-size: 12px;

            @media (min-width: 1310px) {
                font-size: 14px;
            }
        }

        &:hover {
            .top__item-name {
                color: #f1dc97;
            }
        }
    }

    &__pre {
        //width: calc(100% - 252px);
        width: 100%;
    }

    .owl-prev,
    .owl-next {
        @extend %extend-flex-row-center-center;
        cursor: pointer;
        background: none;

        .material-symbols-rounded {
            @extend %extend-flex-row-center-center;
            color: #ffffff !important;
            font-size: 30px;
            transition: .2s;
            $size: 50px;
            width: $size;
            height: $size;

            @media (min-width: 1310px) {
                font-size: 50px;
            }

            &:hover {
                color: #f1dc97 !important;
            }
        }

        &.disabled {
            opacity: 0;
            cursor: default !important;
        }
    }

    .owl-prev {
        position: absolute;
        top: calc(50% - 15px);
        left: -35px;

        @media (min-width: 1310px) {
            left: -51px;
        }
    }

    .owl-next {
        position: absolute;
        top: calc(50% - 15px);
        right: -35px;

        @media (min-width: 1310px) {
            right: -51px;
        }
    }

    .owl-dots {
        @extend %extend-flex-row-center-center;
        width: 100%;
        position: relative;
        margin-top: 20px;

        @media screen and (max-width: $breakpointMid) {
            margin-top: 10px;
        }
    }

    .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 2.5px;
        background: rgba(0, 0, 0, .2) !important;
        transition: 0.2s;
        border: 1px rgba(255, 255, 255, .2) solid !important;
        

        &.active {
            background: $color_white !important;
        }
    }
}

.content {
    position: relative;
    overflow: hidden;

    padding: {
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
    }

    @media (min-width: 1310px) {
        padding: {
            top: 50px;
            bottom: 50px;
            right: 0;
            left: 0;
        }
    }

    @media screen and (max-width: $breakpointMid) {
        background: $color_white;
        padding: 0;
    }

    &__heading {
        font-size: 20px;
        font-weight: bold;
        display: block;
        margin-bottom: 20px;
    }

    &__center {
        @extend %extend-flex-row-between-start;
        backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 12px;
        position: relative;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        padding: 10px;
        padding-top: 20px;

        max-width: #{$max_width - 40px};
        min-width: #{$min_width - 40px};

        @media (min-width: 1310px) {
            max-width: #{$max_width + 40px};
            min-width: #{$min_width + 40px};
            padding: 20px;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            padding: 0;
            background: 0;
            border-radius: 0;
            border: none !important;

            &--padding {
                padding: 10px;
                padding-bottom: 20px;
            }
        }

        &--padding-bottom-0 {
            padding-bottom: 0;
        }

        &--column {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__left {
        width: 880px;

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
        }
    }

    &__right {
        width: calc(100% - 900px);
        min-width: 330px;
        margin-left: 10px;

        @media screen and (max-width: $breakpointMid) {
            display: none;
        }
    }

    &__nomer {
        width: 100%;
    }

    &__avtoram {
        padding: 20px;
        width: 100%;
        backdrop-filter: blur(16px) saturate(180%);
        background: none;
        border-radius: 6px;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

    }
}

.avtory {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-template-areas:
        ".";

    @media (min-width: 1310px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 20px 20px;
        grid-template-areas:
            ". . .";
    }

    @media screen and (max-width: $breakpointMid) {
        grid-template-columns: 1fr;
        gap: 10px 10px;
        grid-template-areas:
        ".";
        text-align: left;
    }

    &__item {
        display: block;
        backdrop-filter: blur(16px) saturate(180%);
        width: 100%;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);
        padding: 10px;
        border-radius: 3px;
        font-size: 14px;
    }
}

.news {
    border-radius: 12px;
    overflow: hidden;

    backdrop-filter: blur(16px) saturate(180%);
    background: none;
    background-color: #FFFDFE;
    border-radius: 12px;

    border: {
        top: 1px solid $color_white;
        left: 1px solid $color_white;
        bottom: 1px solid #EDEDED;
        right: 1px solid #EDEDED;
    }

    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
    20px 20px 100px rgba(255, 255, 255, .05);

    position: relative;
    z-index: 200;
    @extend %extend-flex-row-start-start;
    align-items: stretch;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpointMid) {
        border: none !important;
        box-shadow: 0;
        border-radius: 0;
        background: $color_white;
    }

    &__header {

        width: 100%;

        &:hover {
            .news__header-heading {
                color: #6C6242;
            }

            .news__header-image {
                &::before {
                    opacity: 1;
                }
            }
        }

        &-image {
            width: 100%;
            aspect-ratio: 409/309;
            background: center no-repeat;
            background-size: cover;
            display: block;
            cursor: pointer;
            position: relative;

            @media screen and (max-width: $breakpointMid) {
                height: auto;
                aspect-ratio: 409/309;
            }

            &--page {
                height: 820px;
            }

            &::before {
                @extend %extend-content-absolute-0-100;
                background: rgba(35, 31, 32, .2);
                transition: .2s;
                opacity: 0;
            }
        }

        &-down {

            padding: 20px;
            width: calc(100% - 40px);
            margin-top: -20px;
            margin-left: 20px;
            padding-bottom: 20px;

            position: relative;
            border-radius: 6px 6px 0 0;
            background-color: $color_white;
            background-color: #FFFDFE;

            @media (min-width: 1310px) {
                padding: 40px;
                width: calc(100% - 80px);
                margin-top: -40px;
                margin-left: 40px;
                padding-bottom: 20px;
            }

            @media screen and (max-width: $breakpointMid) {
                padding: 20px;
                width: calc(100% - 40px);
                margin-left: 20px;
                background-color: $color_white;
            }

            &--new {
                border-radius: 0 6px 0 0;
            }
        }

        &-label {
            position: absolute;
            top: -30px;
            left: 0;
            background: #231F20;
            color: $color_white;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            padding: 0 10px;
            @extend %extend-flex-row-center-center;

            @media screen and (max-width: $breakpointMid) {
                font-size: 10px;
            }
            
        }

        &-heading {
            font-size: 20px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            cursor: pointer;
            transition: .2s;
            text-decoration: underline;

            @media screen and (max-width: $breakpointMid) {
                font-size: 18px;
            }
        }

        &-description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    /*&__content { 
        padding: 0 20px;
    }*/

    &__article {
        margin-left: 10px;
        margin-top: 10px;
        position: relative;
        width: calc(100% / 2 - 15px);

        @media (min-width: 1310px) {
            margin-left: 20px;
            margin-top: 20px;
            width: calc(100% / 2 - 30px);
        }

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }

        &:hover {
            .news__article-heading {
                color: #6C6242;
            }

            .news__article-image {
                &::before {
                    opacity: 1;
                }
            }
        }

        &-image {
            width: 100%;
            aspect-ratio: 409/309;
            background: center no-repeat;
            background-size: cover;
            display: block;
            cursor: pointer;
            position: relative;
            border-radius: 6px;
            overflow: hidden;

            @media screen and (max-width: $breakpointMid) {
                height: auto;
                aspect-ratio: 409/309;
            }

            &--page {
                aspect-ratio: 409/309;
            }

            &::before {
                @extend %extend-content-absolute-0-100;
                background: rgba(35, 31, 32, .2);
                transition: .2s;
                opacity: 0;
            }
        }

        &-down {
            background: $color_white;
            background-color: #FFFDFE;
            position: relative;
            border-radius: 6px;
            overflow: hidden;

            padding: 20px;
            width: calc(100% - 20px);
            margin-top: -20px;
            margin-left: 10px;

            @media (min-width: 1310px) {
                padding: 20px;
                width: calc(100% - 40px);
                margin-top: -20px;
                margin-left: 20px;
            }
            
            @media screen and (max-width: $breakpointMid) {
                background: $color_white;
                padding: 20px;
                width: calc(100% - 40px);
                margin-top: -20px;
                margin-left: 20px;
            }
        }

        &-label {
            position: absolute;
            top: -30px;
            left: 0;
            background: #231F20;
            color: $color_white;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            padding: 0 10px;
            @extend %extend-flex-row-center-center;
        }

        &-heading {
            font-size: 18px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            cursor: pointer;
            transition: .2s;
            text-decoration: underline;

            @media screen and (max-width: $breakpointMid) {
                font-size: 16px;
            }
        }

        &-description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &--arhiv {
        background: none;
        backdrop-filter: none;
        padding: 0;
        box-shadow: none;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 20px;

        @media screen and (max-width: $breakpointMid) {
            margin-top: 10px;
        }

        .news__article {
            width: calc(100% / 3 - 13px);

            @media (min-width: 1310px) {
                width: calc(100% / 3 - 26px);
            }

            @media screen and (max-width: $breakpointMid) {
                width: 100%;
            }

            &-image {
                aspect-ratio: 409/309;

                @media screen and (max-width: $breakpointMid) {
                    height: auto;
                    aspect-ratio: 409/309;
                }
            }
        }
    }
}

.telegram {
    padding: 0 13px;
    width: 100%;
    @extend %extend-flex-row-center-center;
    margin-bottom: 13px;

    @media screen and (max-width: $breakpointMid) {
        padding: 0;
    }

    &__button {
        @extend %extend-flex-row-center-center;
        text-align: center;
        width: 100%;
        height: 30px;
        border-radius: 8px;
        background-color: #1D98DC;
        transition: .2s;
        font-size: 12px;
        color: $color_white;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        font-weight: bold;

        &::before {
            $size: 30px;
            width: $size;
            height: $size;
            background: url(../images/icons/telegram.png) center no-repeat;
            background-size: 15px;
            margin-right: 2px;
            content: '';
        }

        &:hover {
            background-color: #0E8ED4;
        }
    }
}

.vkb {
    padding: 0 13px;
    width: 100%;
    @extend %extend-flex-row-center-center;
    margin-bottom: 13px;

    display: none;

    @media screen and (max-width: $breakpointMid) {
        display: flex;
        padding: 0;
    }

    &__button {
        @extend %extend-flex-row-center-center;
        text-align: center;
        width: 100%;
        height: 30px;
        border-radius: 8px;
        background-color: #5281B8;
        transition: .2s;
        font-size: 12px;
        color: $color_white;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        font-weight: bold;

        &::before {
            $size: 30px;
            width: $size;
            height: $size;
            background: url(../images/icons/vk.png) center no-repeat;
            background-size: 15px;
            margin-right: 2px;
            content: '';
        }

        &:hover {
            background-color: #759AC6;
        }
    }
}

.msearch {
    position: fixed;
    top: 60px;
    border-top: 1px #EDEDED solid;
    border-bottom: 1px #EDEDED solid;
    background: $color_white;
    z-index: 999;
    width: 100%;
    padding: 0 10px;
    padding-bottom: 10px;
    display: none;

    @media screen and (max-width: $breakpointMid) {
        display: none;

        &--show {
            display: block;
        }
    }

    .head__form {
        width: 100%;
    }
}

.mright {
    position: fixed;
    top: 60px;
    border-top: 1px #EDEDED solid;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 999;
    background: $color_white;
    overflow-y: scroll;
    display: none;

    @media screen and (max-width: $breakpointMid) {
        display: none;

        &--show {
            display: block;
        }
    }
}

.block {
    padding: 10px;
    padding-top: 20px;
    width: 100%;
    margin-bottom: 10px;
    backdrop-filter: blur(16px) saturate(180%);
    background: none;
    border-radius: 12px;

    @media (min-width: 1310px) {
        padding: 20px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: $breakpointMid) {
        border-radius: 0;
        margin-bottom: 0;
        background: $color_white;
        backdrop-filter: none;
        box-shadow: none;
        border: {
            right: 0;
            left: 0;
        }

        #vk_groups {
           display: none;
        }
    }

    border: {
        top: 1px solid $color_white;
        left: 1px solid $color_white;
        bottom: 1px solid #EDEDED;
        right: 1px solid #EDEDED;
    }

    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
    20px 20px 100px rgba(255, 255, 255, .05);


    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    &__heading {
        display: block;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        line-height: 1;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &::before {
            $size: 16px;
            background-color: rgba(0, 0, 0, .5);
            width: 3px;
            height: $size;
            content: '';
            position: absolute;
            top: 0;
            left: -11px;

            @media (min-width: 1310px) {
                left: -21px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -10px;
            width: calc(100% + 20px);
            height: 1px;
            background: #EDEDED;

            @media (min-width: 1310px) {
                width: calc(100% + 40px);
                left: -20px;
            }
        }
    }

    &__image {
        width: 100%;
        height: 250px;
        background: center no-repeat;
        background-size: cover;
        cursor: pointer;
        display: block;
        position: relative;
        border-radius: 6px;
        overflow: hidden;

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        &::before {
            @extend %extend-content-absolute-0-100;
            background: linear-gradient(to bottom, rgba(35, 31, 32, 0.6), transparent, rgba(35, 31, 32, 0.9));
        }

        &::after {
            @extend %extend-content-absolute-0-100;
            background: rgba(35, 31, 32, .2);
            transition: .2s;
            opacity: 0;
        }

        &-heading {
            position: absolute;
            z-index: 100;
            bottom: 0;
            left: 0;
            width: 100%;
            font-size: 16px;
            display: block;
            padding: 20px;
            font-weight: bold;
            color: $color_white
        }

        &-date {
            font-size: 12px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 100;
            color: $color_white
        }
    }

    &__list {
        @extend %extend-flex-column-start-start;
        margin-top: 20px;

        @media screen and (max-width: $breakpointMid) {
            margin-top: 10px;
        }

        &-item {
            @extend %extend-flex-row-start-start;
            
            align-items: stretch;
            border-bottom: 1px #EDEDED solid;
            margin-bottom: 10px;
            padding-bottom: 10px;
            width: 100%;

            @media (min-width: 1310px) {
                margin-bottom: 20px;
                padding-bottom: 20px;
            }

            @media screen and (max-width: $breakpointMid) {
                margin-bottom: 10px;
                padding-bottom: 10px;
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }

            &:hover {
                .block__list-heading {
                    color: #6C6242;
                }

                .block__list-image {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        &-right {
            @extend %extend-flex-column-start-start;
        }

        &-heading {
            font-weight: bold;
            transition: .2s;
            text-decoration: underline;
        }

        &-date {
            font-size: 11px;
            margin-bottom: 5px;
        }

        &-image {
            $size: 80px;
            width: $size;
            min-width: $size;
            height: $size;
            background: center no-repeat;
            background-size: cover;
            margin-right: 10px;
            position: relative;
            border-radius: 6px;
            overflow: hidden;

            &::before {
                @extend %extend-content-absolute-0-100;
                background: rgba(35, 31, 32, .2);
                transition: .2s;
                opacity: 0;
            }
        }
    }

    &__calendar {
        padding: 10px;
        border: 1px #EDEDED solid;
        position: relative;
        border-radius: 6px;
        margin-bottom: 20px;

        @media (min-width: 1310px) {
            padding: 20px;
        }

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        &-date {
            background: $color_white;
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            height: 20px;
            padding: 0 10px;
            top: -10px;
            left: 10px;
            @extend %extend-flex-row-center-center;
            line-height: 1;
        }

        &-content {
            font-size: 14px;
            line-height: 1.1;

            img {
                width: 100%;
                object-fit: content;
                border-radius: 3px;
                margin-bottom: 10px;
            }
        }
    }

}

.arhiv {
    border-radius: 12px;
    padding-bottom: 0;

    &__item {
        width: calc(100% / 4 - 15px);

        @media screen and (max-width: $breakpointMid) {
            width: calc(100% / 2 - 5px);
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 10px;

            &:nth-child(2n) {
                margin-right: 0 !important; 
            }
        }
        @extend %extend-flex-column-center-center;

        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 6px;
        padding: 10px;
        display: block;
        overflow: hidden;

        @media (min-width: 1310px) {
            padding: 20px;
        }

        backdrop-filter: blur(16px) saturate(180%);
        background: none;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        &:nth-child(4n) {
            margin-right: 0;

            @media screen and (max-width: $breakpointMid) {
                margin-right: 20px;
            }
        }

        &:hover {
            .arhiv__name {
                color: #6C6242;
            }
        }
    }

    &__image {
        border-radius: 3px;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 650/900;
    }

    &__name {
        display: block;
        margin-top: 5px;
        transition: .2s;
        text-align: center;

        @media (min-width: 1310px) {
            margin-top: 10px;
        }

        @media screen and (max-width: $breakpointMid) {
            margin-top: 10px;
        }
    }

    &__year {
        &-name {
            font-size: 20px;
            font-weight: bold;
            display: block;
            margin-bottom: 20px;

            @media screen and (max-width: $breakpointMid) {
                margin-top: 10px;

                &--padding {
                    padding: 0 10px;
                    margin-top: 20px;
                    margin-bottom: 0
                }
            }
        }

        &-content {
            @extend %extend-flex-row-start-start;
            flex-wrap: wrap;
            align-items: stretch;
        }

    }
}

.year {
    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid $color_white;

    box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);
    color: #ffffff;
    padding: 20px 0;
    padding-bottom: 10px;

    @media screen and (max-width: $breakpointMid) {
        padding: 10px 0;
        padding-bottom: 0;
    }

    &__center {
        @extend %extend-flex-row-start-start;
        flex-wrap: wrap;
        padding: 0 20px;
        @media (min-width: 1310px) {
            padding: 0;
        }

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }

        @media screen and (max-width: $breakpointMid) {
            padding: 0 10px;
        }
    }

    &__item {
        display: block;
        padding: 10px 0;
        width: calc(100% / 5 - 8px);
        text-align: center;
        line-height: 1;
        border: 1px rgba(255, 255, 255, .1) solid;
        margin-right: 10px;
        margin-bottom: 10px;
        transition: .2s;
        font-size: 16px;
        border-radius: 3px;

        @media (min-width: 1310px) {
            width: 116px;
        }

        @media screen and (max-width: $breakpointMid) {
            width: calc(100% / 3 - 7px);
            font-size: 14px;

            &:nth-child(3n) {
                margin-right: 0 !important;
            }
        }

        &:nth-child(5n) {
            margin-right: 0;

            @media (min-width: 1310px) {
                margin-right: inherit;
            }

            @media screen and (max-width: $breakpointMid) {
                margin-right: 10px;
            }
        }

        &:nth-child(10n) {

            @media (min-width: 1310px) {
                margin-right: 0;
            }

            @media screen and (max-width: $breakpointMid) {
                margin-right: 10px;
            }
        }

        &:hover {
            border-color: #655B37;
            color: #F1DC97;
            background: rgba(0, 0, 0, 0.1);
            border: 1px rgba(255, 255, 255, .5) solid;
        }

        &--active {
            color: #F1DC97;
            border-color: #F1DC97;
            font-weight: bold;
            background: rgba(0, 0, 0, 0.4);
            //box-shadow: -20px -20px 100px rgba(0,0,0,.05),
            //20px 20px 100px rgba(255,255,255,.05);

            &:hover {
                border-color: #F1DC97;
                color: #F1DC97;
            }
        }
    }
}

.article {
    width: 100%;

    @media screen and (max-width: $breakpointMid) {
        padding: 0;
    }

    &__main {
        @extend %extend-flex-row-start-start;
        flex-wrap: wrap;
        width: 100%;

        backdrop-filter: blur(16px) saturate(180%);
        background: none;
        border-radius: 12px;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        margin-top: 20px;

        @media screen and (max-width: $breakpointMid) {
            border-radius: 0;
            border: 0;
            backdrop-filter: none;
            box-shadow: none;
            margin-top: 0;
            box-shadow: none;
        }
        
        .news__article {
            width: calc(100% / 3 - 13px);

            @media (min-width: 1310px) {
                width: calc(100% / 3 - 26px);
            }

            @media screen and (max-width: $breakpointMid) {
                width: calc(100% - 20px);
            }
        }

        .news__article-image {
            aspect-ratio: 409/309;

            @media screen and (max-width: $breakpointMid) {
                height: auto;
                aspect-ratio: 409/309;
            }
        }
    }

    &__image {
        width: 100%;
        aspect-ratio: 409/309;
        max-height: 60vh;
        background: center no-repeat;
        background-size: cover;
        border-radius: 6px 6px 0 0;
        position: relative;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            //bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        @media screen and (max-width: $breakpointMid) {
            border: 0;
            border-radius: 0;
            height: auto;
            aspect-ratio: 3/4;
        }

        &-description {
            font-size: 16px;
            transition: .2s;
            position: absolute;
            bottom: 0;
            right: 0;
            color: $color_white;
            width: 100%;
            @extend %extend-flex-row-center-center;
            text-align: center;
            padding: 30px;
            padding-left: 240px;
            background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .7));

            @media screen and (max-width: $breakpointMid) {
                padding: 20px;
            }
        }

        &-name {
            text-shadow: 0px 0 6px rgba(0, 0, 0, .5);
            text-align: center;
            padding: 0 40px;

            @media (min-width: 1310px) {
                padding: 0 100px;
            }

            @media screen and (max-width: $breakpointMid) {
                padding: 0 10px;
            }
        }
    }

    &__ul {
        @extend %extend-flex-row-start-center;
        list-style: none;
        position: absolute;
        top: 20px;
        left: 20px;

        @media screen and (max-width: $breakpointMid) {
            top: 10px;
            left: 10px;
            flex-wrap: wrap;
        }
    }

    &__li {
        padding: 8px 15px;
        font-size: 12px;
        margin-right: 10px;
        backdrop-filter: blur(16px) saturate(180%);
        background: rgba(255, 255, 255, .7);
        border-radius: 50px;
        transition: .2s;
        display: block;

        @media screen and (max-width: $breakpointMid) {
            margin-right: 5px;
            font-size: 11px;
            margin-bottom: 5px;
        }

        &--date {
            cursor: default;
        }

        strong {
            transition: .2s;
        }

        &--author {
            cursor: pointer;

            &:hover {
                strong {
                    color: #6B6141;
                }
            }
        }

        &--tag {
            cursor: pointer;

            &:hover {
                color: #6B6141;
            }
        }

        &:nth-last-child(1) {
            margin-right: 0;

            @media screen and (max-width: $breakpointMid) {
                margin-bottom: 0;
            }
        }
    }

    &__album {
        position: absolute;
        left: 20px;
        bottom: 20px;
        width: 200px;
        aspect-ratio: 650/900;
        background: #8C8C8E center no-repeat;
        background-size: cover;
        border-radius: 3px;

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        display: block;
        cursor: pointer;
        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        @media screen and (max-width: $breakpointMid) {
            display: none;
        }
    }

    &__top {
        @extend %extend-flex-column-start-start;
        margin-left: 220px;

        @media screen and (max-width: $breakpointMid) {
            margin-left: 0;
            justify-content: center;
            align-items: center;
        }
    }

    &__name {
        font-size: 26px;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 30px;
        transition: 0.2s;
        text-decoration: underline;

        @media screen and (max-width: $breakpointMid) {
            font-size: 20px;
        }
    }

    &__nomer {
        cursor: pointer;
        display: inline-block;
        color: #6B6142;
    }

    &__pod {
        background: #6B6142;
        color: $color_white;
        text-transform: uppercase;
        padding: 8px 15px;
        border-radius: 50px;
        font-size: 10px;
        margin-top: 10px;
        position: relative;
        overflow: hidden;

        * {
            position: relative;
            z-index: 100;
        }

        &::after {
            margin-left: -30px;
            content: " ";
            width: 30px;
            height: 300px;
            background: #fff;
            -webkit-animation-delay: 50ms;
            animation-delay: 50ms;
            position: absolute;
            left: -40px;
            top: -150px;
            top-animation-timing-function: ease-in-out;
            transition: all 0.1s;
            -webkit-animation-name: slideme;
            animation-name: slideme;
            -webkit-animation-duration: 3.5s;
            animation-duration: 3.5s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            transform: rotate(45deg);
        }
    }

    &__down {
        width: 100%;
        padding: 60px 20px;
        width: 100%;
        margin-bottom: 20px;
        backdrop-filter: blur(16px) saturate(180%);
        background: none;
        border-radius: 0 0 6px 6px;

        @media (min-width: 1310px) {
            margin-bottom: 40px;
        }

        border: {
            //top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        @media screen and (max-width: $breakpointMid) {
            border: 0;
            border-bottom: 1px solid #EDEDED;
            padding: 20px 10px;
            border-radius: 0;
            margin-bottom: 20px;
            text-align: center;
            background: $color_white;
            box-shadow: none;
        }
    }

    &__lock {
        backdrop-filter: blur(16px) saturate(180%);
        background: none;
        border-radius: 6px;
        padding: 30px;
        margin-top: 20px;

        @media (min-width: 1310px) {
            padding: 60px;
        }

        @media screen and (max-width: $breakpointMid) {
            padding: 20px;
            border-radius: 0;
        }

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        &-span {
            @extend %extend-flex-row-start-center;
            font-size: 20px;
            margin-bottom: 30px;

            @media screen and (max-width: $breakpointMid) {
                font-size: 19px;
            }

            .material-symbols-rounded {
                display: block;
                margin-right: 20px;
                cursor: default;
            }
        }

        &-txt {
            a {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: $breakpointMid) {
        .text {
            padding: {
                right: 10px;
                left: 10px;
            }
        }
    }
}

.red {
    @extend %extend-flex-row-start-start;
    flex-wrap: wrap;
    align-items: stretch;

    &__item {
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 10px;
        @extend %extend-flex-row-start-start;
        border-radius: 6px;

        backdrop-filter: blur(16px) saturate(180%);
        background: none;
        padding: 20px;

        @media (min-width: 1310px) {
            margin-right: 20px;
            margin-bottom: 20px;
            width: calc(50% - 10px);
        }

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        @media screen and (max-width: $breakpointMid) {
            width: 100%;
            margin-right: 0;
            flex-direction: column;
            margin-bottom: 10px;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-block {
            &:nth-last-child(1) {
                margin-left: 20px;

                @media screen and (max-width: $breakpointMid) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }

        &-image {
            width: 100%;
            object-fit: cover;
            border-radius: 3px;
            width: 255px;
            height: 355px;

            @media screen and (max-width: $breakpointMid) {
                width: 100%;
                aspect-ratio: 3/4;
                height: auto;
            }
        }

        &-job {
            display: block;
            margin-bottom: 10px;
            font-weight: bold;
        }

        &-name {
            font-size: 20px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            transition: 0.2s;
            text-decoration: underline;
            margin-bottom: 20px;
        }

        &-description {
            display: block;
        }
    }
}

.text {
    font-size: 19px;
    line-height: 1.5;
    text-align: justify;

    @media screen and (max-width: $breakpointMid) {
        font-size: 17px;
        line-height: 1.3;
    }

    img {
        width: 100% !important;
        border-radius: 6px;
        object-fit: cover;
        
        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        @media screen and (max-width: $breakpointMid) {
            object-fit: contain;
            height: auto !important;
            box-shadow: none;
        }
    }

    p {
        margin-bottom: 30px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
        transition: .2s;

        &:hover {
            color: #6B6142;
        }
    }
}

.paginator {
    overflow: hidden;
    @extend %extend-flex-row-center-center;
    width: 100%;
    //font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;

    @media screen and (max-width: $breakpointMid) {
        margin-top: 20px;
    }

    &__icon {
        @extend %extend-flex-row-center-center;
        $size: 30px;
        width: $size;
        height: $size;
        font-size: 24px;
        transition: .2s;
        margin-left: 5px;
        padding: 20px;
        backdrop-filter: blur(16px) saturate(180%);
        background: #FFFDFE;
        border-radius: 3px;
        cursor: pointer;

        @media screen and (max-width: $breakpointMid) {
            background: $color_white;
            width: 30px;
            height: 30px;
            padding: 10px;
        }

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);

        &:not(.paginator__icon--disable):hover {
            color: #6B6142;
        }

        &--disable {
            color: #C3CAD2;
            cursor: default;

            .material-symbols-rounded {
                cursor: default;
            }
        }
    }

    &__block {
        @extend %extend-flex-row-center-center;
    }

    &__item {
        @extend %extend-flex-row-center-center;
        $size: 30px;
        width: $size;
        height: $size;
        transition: .2s;
        margin-right: 5px;

        padding: 20px;
        backdrop-filter: blur(16px) saturate(180%);
        background: #FFFDFE;
        border-radius: 3px;
        cursor: pointer;


        @media screen and (max-width: $breakpointMid) {
            background: $color_white;
            width: 30px;
            padding: 10px;
            height: 30px;
        }

        &:nth-child(1) {
            margin-left: 5px;
        }

        border: {
            top: 1px solid $color_white;
            left: 1px solid $color_white;
            bottom: 1px solid #EDEDED;
            right: 1px solid #EDEDED;
        }

        box-shadow: -20px -20px 100px rgba(0, 0, 0, .05),
        20px 20px 100px rgba(255, 255, 255, .05);


        &:nth-last-child(1) {
            margin-right: 0;
        }

        &:not(.paginator__item--active):hover {
            color: #6B6142;
        }

        &--active {
            background: rgba(0, 0, 0, .7);
            color: $color_white;
        }
    }
}

#scrollToTop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    transition: opacity 0.2s;
    @extend %extend-flex-row-center-center;
    $size: 50px;
    width: $size;
    height: $size;
    color: $color_white;
    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, .7);

    @media (min-width: 1310px) {
        bottom: 20px;
        right: 20px;
    }

    @media screen and (max-width: $breakpointMid) {
        width: 45px;
        height: 45px;
        bottom: 5px;
        right: 5px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .8);
    }
}

