@font-face {
  font-family: "CeraPro";
  src: url("fonts/CeraPro-Light.eot");
  src: url("fonts/CeraPro-Light.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Light.woff") format("woff"), url("fonts/CeraPro-Light.ttf") format("truetype"), url("fonts/CeraPro-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CeraPro";
  src: url("fonts/CeraPro-Regular.eot");
  src: url("fonts/CeraPro-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Regular.woff") format("woff"), url("fonts/CeraPro-Regular.ttf") format("truetype"), url("fonts/CeraPro-Regular.svg?#webfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CeraPro";
  src: url("fonts/CeraPro-Medium.eot");
  src: url("fonts/CeraPro-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Medium.woff") format("woff"), url("fonts/CeraPro-Medium.ttf") format("truetype"), url("fonts/CeraPro-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "CeraPro";
  src: url("fonts/CeraPro-Bold.eot");
  src: url("fonts/CeraPro-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/CeraPro-Bold.woff") format("woff"), url("fonts/CeraPro-Bold.ttf") format("truetype"), url("fonts/CeraPro-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
.head__center, .mnav__top, .nav__ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mnav__top-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#scrollToTop, .paginator__item, .paginator__block, .paginator__icon, .paginator, .article__image-description, .block__calendar-date, .vkb__button, .vkb, .telegram__button, .telegram, .news__article-label, .news__header-label, .top .owl-dots, .top .owl-prev .material-symbols-rounded,
.top .owl-next .material-symbols-rounded, .top .owl-prev,
.top .owl-next, .foot__social-item, .head__form .material-symbols-rounded, .mnav__center, .mnav__top-block .material-symbols-rounded, .nav__link, .button-white, .button, .heading__low, .white-dot, .green-dot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.red__item, .red, .article__main, .year__center, .arhiv__year-content, .block__list-item, .news {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.article__top, .block__list-right, .block__list, .foot__logos, .foot__block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.article__lock-span, .article__ul, .foot__social, .head__block, .mnav__top-block, .nav__center, .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.content__center, .top__center, .foot__center, .footer__center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.arhiv__item, .footer__new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block__list-image::before, .block__image::after, .block__image::before, .news__article-image::before, .news__header-image::before, .footer__new-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::selection {
  background: rgba(35, 31, 32, 0.9);
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #6B6142;
}

::-webkit-resizer,
::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-family: inherit;
  font-weight: normal;
}

button,
input,
select,
textarea {
  transition: 0.2s;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

html {
  width: 100%;
  min-height: 100%;
  font-family: "CeraPro", Arial, sans-serif;
}
@media screen and (max-width: 990px) {
  html {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
}

body {
  background: #ffffff;
  color: #1B222D;
  position: relative;
  resize: none;
  outline: none;
  overflow-x: hidden;
  font-size: 14px;
  background: #CBD2E5;
  background: radial-gradient(at left top, #CBD2E5, #fff1eb, #D5CCE3);
}
@media screen and (max-width: 990px) {
  body {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    padding-top: 111px;
  }
}

.progress-bar {
  position: fixed;
  left: 0;
  top: 0;
  height: 4px;
  width: 0;
  z-index: 998;
  background: #0074AF;
}

.desktop {
  display: block;
}
@media screen and (max-width: 990px) {
  .desktop {
    display: none;
  }
}

.mobile {
  display: none;
}
@media screen and (max-width: 990px) {
  .mobile {
    display: block;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.center {
  max-width: 1250px;
  min-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 990px) {
  .center {
    width: 100%;
    padding: 0 10px;
  }
}

.min-width {
  width: 1222px;
  margin: 0 auto;
}
@media screen and (max-width: 990px) {
  .min-width {
    width: 100%;
  }
}

.margin-top-0px {
  margin-top: 0 !important;
}

.margin-top-10px {
  margin-top: 10px !important;
}

.margin-top-20px {
  margin-top: 20px !important;
}

.margin-top-30px {
  margin-top: 30px !important;
}

.margin-top-40px {
  margin-top: 40px !important;
}

.margin-top-50px {
  margin-top: 50px !important;
}

.margin-bottom-0px {
  margin-bottom: 0 !important;
}

.margin-bottom-10px {
  margin-bottom: 10px !important;
}

.margin-bottom-20px {
  margin-bottom: 20px !important;
}

.margin-bottom-30px {
  margin-bottom: 30px !important;
}

.margin-bottom-40px {
  margin-bottom: 40px !important;
}

.margin-bottom-50px {
  margin-bottom: 50px !important;
}

.margin-right-10px {
  margin-right: 10px !important;
}

.margin-right-20px {
  margin-right: 20px !important;
}

.margin-right-30px {
  margin-right: 30px !important;
}

.margin-right-40px {
  margin-right: 40px !important;
}

.margin-right-50px {
  margin-right: 50px !important;
}

.width-auto {
  width: auto !important;
}

@keyframes opas {
  0% {
    opacity: 0.2;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.green-dot {
  background-color: #1cbc54;
  width: 5px;
  height: 5px;
  margin-right: 6px;
  border-radius: 100%;
  animation: opas 1.1s infinite linear;
}

.white-dot {
  background-color: #ffffff;
  width: 5px;
  height: 5px;
  margin-right: 6px;
  border-radius: 100%;
  animation: opas 1.1s infinite linear;
}

.checkbox {
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
}
.checkbox .material-symbols-rounded {
  margin-right: 5px;
}
.checkbox__name {
  display: block;
  width: 100%;
}
.checkbox input[type=checkbox] {
  display: none;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate2 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes bounce-3 {
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
  0% {
    transform: rotate(-5deg) scale(1.4);
    opacity: 0.2;
  }
}
@keyframes pulse3 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(56, 188, 129, 0.5);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(56, 188, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(56, 188, 129, 0);
  }
}
@keyframes pulse22 {
  0% {
    box-shadow: 0 0 0 0 rgba(56, 188, 129, 0.5);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(56, 188, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(56, 188, 129, 0);
  }
}
@keyframes pulse222 {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 117, 175, 0.5);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(0, 117, 175, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 117, 175, 0);
  }
}
@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 117, 175, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 117, 175, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 117, 175, 0);
  }
}
.material-symbols-rounded--fill {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}

.description {
  font-size: 24px;
  display: block;
  line-height: 1.1;
  font-family: "PorscheNextTT";
  margin-top: 15px;
}
@media screen and (max-width: 990px) {
  .description {
    font-size: 22px;
    margin-top: 10px;
  }
}
.description strong {
  color: #38BC80;
  font-weight: 500;
}

.heading {
  font-size: 40px;
  line-height: 1.1;
  display: block;
  font-weight: 500;
  color: #1B222D;
}
@media screen and (max-width: 990px) {
  .heading {
    font-size: 26px;
  }
}
.heading strong {
  font-weight: 700;
}
.heading--center {
  text-align: center;
}
.heading__low {
  font-size: 30px;
  line-height: 1.8;
}
@media screen and (max-width: 990px) {
  .heading__low {
    font-size: 16px;
  }
}
.heading__low .material-symbols-rounded {
  margin-left: 10px;
  margin-right: 5px;
  color: #EBA801;
}

.button {
  background: #6C6242;
  color: #ffffff;
  border: 0;
  padding: 0 20px;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  border-radius: 50px;
}
.button .material-symbols-rounded {
  margin-left: 5px;
}
.button * {
  position: relative;
  z-index: 100;
}
.button::after {
  margin-left: -30px;
  content: " ";
  width: 30px;
  height: 300px;
  background: #fff;
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
  position: absolute;
  left: -40px;
  top: -150px;
  top-animation-timing-function: ease-in-out;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background: url(../images/icons/outline_keyboard_arrow_down_white_24dp.png) right 10px center no-repeat;
  background-size: 20px;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  backdrop-filter: blur(5px);
}
.label__name {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 20px;
  font-size: 16px;
}
.label__input {
  display: block;
  width: 100%;
  border: 0;
  background-color: rgba(255, 255, 255, 0.8);
  height: 55px;
  padding: 0 20px;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  color: #1a2b38 !important;
  font-weight: 600;
  font-family: inherit;
  font-size: 16px;
}
.label__input::placeholder {
  color: #1a2b38 !important;
  font-weight: 200;
  opacity: 0.8;
}
.label__input option {
  color: #111111;
}

.checkbox {
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
}
.checkbox .material-symbols-rounded {
  margin-right: 5px;
}
.checkbox__name {
  display: block;
  width: 100%;
}
.checkbox input[type=checkbox] {
  display: none;
}

.button-white {
  height: 70px;
  padding: 0 30px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  transition: 0.2s;
  background: #0074AF;
  position: relative;
  overflow: hidden;
  animation: pulse-blue 2s infinite;
  border: 0;
  border: 1px rgba(255, 255, 255, 0.25) solid;
}
@media screen and (max-width: 990px) {
  .button-white {
    font-size: 16px;
    height: 60px;
  }
}
.button-white:hover {
  background: #006394;
}
.button-white:hover .material-symbols-rounded {
  transform: translateX(5px);
}
.button-white * {
  position: relative;
  z-index: 100;
}
.button-white::after {
  margin-left: -30px;
  content: " ";
  width: 30px;
  height: 300px;
  background: #fff;
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
  position: absolute;
  left: -40px;
  top: -150px;
  top-animation-timing-function: ease-in-out;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}
.button-white .material-symbols-rounded {
  margin-left: 10px;
  display: block;
  transition: 0.2s;
}

.nav {
  color: #ffffff;
  backdrop-filter: blur(16px) saturate(180%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;
}
@media screen and (max-width: 990px) {
  .nav {
    display: none;
  }
}
.nav__center {
  padding: 0 20px;
  font-size: 13px;
}
@media (min-width: 1310px) {
  .nav__center {
    padding: 0;
    font-size: 14px;
  }
}
.nav__ul {
  list-style: none;
  position: relative;
  width: 100%;
}
.nav__li {
  display: block;
  transition: 0.5s;
  position: relative;
}
.nav__li:nth-last-child(1) {
  margin-right: 0;
}
.nav__li::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}
@media screen and (max-width: 990px) {
  .nav__li::before {
    display: none;
  }
}
.nav__li:hover {
  color: #f1dc97;
}
.nav__li:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}
.nav__li--active {
  color: #f1dc97;
  font-weight: bold;
}
.nav__li--active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #f1dc97;
}
@media screen and (max-width: 990px) {
  .nav__li--active::after {
    display: none;
  }
}
.nav__link {
  height: 60px;
  padding: 0 10px;
}
@media (min-width: 1310px) {
  .nav__link {
    padding: 0 20px;
  }
}

.mnav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  display: none;
  color: #ffffff;
  backdrop-filter: blur(16px) saturate(180%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .mnav {
    display: block;
  }
}
.mnav__top {
  height: 60px;
  max-height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: #ffffff;
  color: #111111;
}
.mnav__top-logo {
  height: 40px;
  width: auto;
  background: url(../images/logo--lite.png) left top no-repeat;
  background-size: contain;
  margin-left: 10px;
  padding-left: 77px;
  font-size: 11px;
  line-height: 1;
}
.mnav__top-logo br {
  display: none;
}
.mnav__top-logo strong {
  color: #6C6242;
}
.mnav__top-block .material-symbols-rounded {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
}
.mnav__top-block .material-symbols-rounded:nth-last-child(1) {
  margin-right: 10px;
}
.mnav__center {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  height: 28px;
  max-height: 28px;
}
.mnav__center strong {
  display: block;
  margin-left: 5px;
  font-size: 14px;
}
.mnav__down {
  padding: 0 10px;
  font-size: 14px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
}
.mnav__down-item {
  display: block;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mnav__down-item--active {
  color: #F1DC97;
  font-weight: bold;
}

.head {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.75));
}
@media screen and (max-width: 990px) {
  .head {
    display: none;
  }
}
.head__center {
  height: 140px;
  padding: 0 20px;
}
@media (min-width: 1310px) {
  .head__center {
    padding: 0;
  }
}
.head__logo {
  width: 130px;
  height: 120px;
  background: url(../images/logo.png) left center no-repeat;
  background-size: contain;
}
.head__text {
  margin-left: 20px;
  font-size: 12px;
}
.head__text strong {
  font-size: 15px;
  color: #655B37;
}
.head__form {
  display: block;
  width: 250px;
  margin-right: 20px;
  position: relative;
  border-bottom: 1px #6C6242 solid;
  transition: 0.2s;
}
.head__form--active {
  width: 350px;
}
.head__form .material-symbols-rounded {
  position: absolute;
  width: 24px;
  height: 50px;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #6C6242;
  border: 0;
}
.head__form-input {
  display: block;
  width: calc(100% - 34px);
  border: 0;
  height: 50px;
}
.head__form-button--disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}

.footer {
  color: #ffffff;
  padding: 30px 0;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.6);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .footer {
    padding: 60px 0;
  }
}
@media screen and (max-width: 990px) {
  .footer {
    padding: 20px 10px;
  }
}
.footer__center {
  align-items: stretch;
  padding: 0 20px;
}
@media (min-width: 1310px) {
  .footer__center {
    padding: 0;
  }
}
@media screen and (max-width: 990px) {
  .footer__center {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0;
  }
}
.footer__block {
  border-radius: 6px;
  padding: 20px;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 33.3333333333%;
  margin-right: 10px;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .footer__block {
    margin-right: 20px;
  }
}
.footer__block:nth-last-child(1) {
  margin-right: 0;
}
@media screen and (max-width: 990px) {
  .footer__block:nth-last-child(1) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 990px) {
  .footer__block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 10px;
  }
}
.footer__heading {
  display: block;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
}
@media screen and (max-width: 990px) {
  .footer__heading {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.footer__heading::before {
  background-color: rgba(255, 255, 255, 0.5);
  width: 3px;
  height: 16px;
  content: "";
  position: absolute;
  top: 0;
  left: -21px;
}
@media screen and (max-width: 990px) {
  .footer__heading::before {
    left: -11px;
    top: 10px;
  }
}
.footer__heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -20px;
  width: calc(100% + 40px);
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 990px) {
  .footer__heading::after {
    left: -10px;
    width: calc(100% + 20px);
  }
}
.footer .block__list-item {
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
}
.footer .block__list-item:hover .block__list-heading {
  color: inherit;
}
.footer__new-image {
  width: 100%;
  aspect-ratio: 650/900;
  background: #8C8C8E center no-repeat;
  background-size: contain;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.footer__new-image::before {
  background: rgba(35, 31, 32, 0.2);
  opacity: 0;
  transition: 0.2s;
}
.footer__new-image:hover::before {
  opacity: 1;
}
.footer__new-name {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
}
@media (min-width: 1310px) {
  .footer__new-name {
    font-size: 20px;
  }
}
@media screen and (max-width: 990px) {
  .footer__new-name {
    font-size: 16px;
    margin-top: 10px;
  }
}

.foot {
  color: #ffffff;
  backdrop-filter: blur(16px) saturate(180%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  width: 100%;
  padding: 30px 0;
  padding-bottom: 70px;
}
@media (min-width: 1310px) {
  .foot {
    padding: 60px 0;
  }
}
@media screen and (max-width: 990px) {
  .foot {
    padding: 20px 10px;
    border-bottom: 0;
  }
}
.foot__center {
  padding: 0 20px;
}
@media (min-width: 1310px) {
  .foot__center {
    padding: 0;
  }
}
@media screen and (max-width: 990px) {
  .foot__center {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }
}
.foot__block--row {
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}
@media screen and (max-width: 990px) {
  .foot__block--row {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-bottom: 20px;
  }
}
.foot__block--end {
  align-items: flex-end;
}
@media screen and (max-width: 990px) {
  .foot__block--end {
    align-items: flex-start;
  }
}
.foot__block--contacts {
  line-height: 1.5;
  text-align: right;
  min-width: 40%;
}
@media screen and (max-width: 990px) {
  .foot__block--contacts {
    text-align: left;
    min-width: auto;
  }
}
.foot__block--contacts a {
  text-decoration: underline;
  cursor: pointer;
}
.foot__social {
  margin-top: 10px;
}
.foot__social-item {
  width: 50px;
  height: 50px;
  background: center no-repeat;
  background-size: 25px;
  cursor: pointer;
}
.foot__social-item--vk {
  background-image: url(../images/icons/vk.png);
}
.foot__social-item--telegram {
  background-image: url(../images/icons/telegram.png);
}
.foot__logo {
  display: block;
  width: 121px;
  min-width: 121px;
  height: 71px;
  background: url(../images/logo--white.png) left center no-repeat;
  background-size: contain;
}
.foot__logos {
  margin-left: 20px;
  margin-right: 20px;
}
@media screen and (max-width: 990px) {
  .foot__logos {
    margin-left: 0;
    margin-top: 20px;
  }
}

.top {
  color: #ffffff;
  padding: 20px 0;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #ffffff;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .top {
    padding: 10px 0;
    padding-bottom: 20px;
    border-bottom: 20px #ffffff solid;
  }
}
.top__center {
  padding: 0 20px;
}
@media (min-width: 1310px) {
  .top__center {
    padding: 0;
  }
}
@media screen and (max-width: 990px) {
  .top__center {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 10px;
  }
}
.top__item {
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  padding: 10px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: 0.2s;
}
@media (min-width: 1310px) {
  .top__item {
    padding: 20px;
  }
}
@media screen and (max-width: 990px) {
  .top__item {
    padding: 0;
    background: none;
    backdrop-filter: none;
    border: 0;
    border-radius: 0;
  }
}
.top__item:hover {
  background-color: rgba(255, 255, 255, 0.35);
}
.top__item-image {
  width: 100%;
  background: #8C8C8E center no-repeat;
  background-size: cover;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  aspect-ratio: 650/900;
}
@media screen and (max-width: 990px) {
  .top__item-image {
    border-radius: 3px;
  }
}
.top__item-name {
  display: block;
  text-align: center;
  margin-top: 5px;
  transition: 0.2s;
  font-size: 12px;
}
@media (min-width: 1310px) {
  .top__item-name {
    font-size: 14px;
  }
}
.top__item:hover .top__item-name {
  color: #f1dc97;
}
.top__pre {
  width: 100%;
}
.top .owl-prev,
.top .owl-next {
  cursor: pointer;
  background: none;
}
.top .owl-prev .material-symbols-rounded,
.top .owl-next .material-symbols-rounded {
  color: #ffffff !important;
  font-size: 30px;
  transition: 0.2s;
  width: 50px;
  height: 50px;
}
@media (min-width: 1310px) {
  .top .owl-prev .material-symbols-rounded,
.top .owl-next .material-symbols-rounded {
    font-size: 50px;
  }
}
.top .owl-prev .material-symbols-rounded:hover,
.top .owl-next .material-symbols-rounded:hover {
  color: #f1dc97 !important;
}
.top .owl-prev.disabled,
.top .owl-next.disabled {
  opacity: 0;
  cursor: default !important;
}
.top .owl-prev {
  position: absolute;
  top: calc(50% - 15px);
  left: -35px;
}
@media (min-width: 1310px) {
  .top .owl-prev {
    left: -51px;
  }
}
.top .owl-next {
  position: absolute;
  top: calc(50% - 15px);
  right: -35px;
}
@media (min-width: 1310px) {
  .top .owl-next {
    right: -51px;
  }
}
.top .owl-dots {
  width: 100%;
  position: relative;
  margin-top: 20px;
}
@media screen and (max-width: 990px) {
  .top .owl-dots {
    margin-top: 10px;
  }
}
.top .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 2.5px;
  background: rgba(0, 0, 0, 0.2) !important;
  transition: 0.2s;
  border: 1px rgba(255, 255, 255, 0.2) solid !important;
}
.top .owl-dot.active {
  background: #ffffff !important;
}

.content {
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 1310px) {
  .content {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 990px) {
  .content {
    background: #ffffff;
    padding: 0;
  }
}
.content__heading {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
}
.content__center {
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  position: relative;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  padding: 10px;
  padding-top: 20px;
  max-width: 1210px;
  min-width: 960px;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .content__center {
    max-width: 1290px;
    min-width: 1040px;
    padding: 20px;
  }
}
@media screen and (max-width: 990px) {
  .content__center {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0;
    background: 0;
    border-radius: 0;
    border: none !important;
  }
  .content__center--padding {
    padding: 10px;
    padding-bottom: 20px;
  }
}
.content__center--padding-bottom-0 {
  padding-bottom: 0;
}
.content__center--column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.content__left {
  width: 880px;
}
@media screen and (max-width: 990px) {
  .content__left {
    width: 100%;
  }
}
.content__right {
  width: calc(100% - 900px);
  min-width: 330px;
  margin-left: 10px;
}
@media screen and (max-width: 990px) {
  .content__right {
    display: none;
  }
}
.content__nomer {
  width: 100%;
}
.content__avtoram {
  padding: 20px;
  width: 100%;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-radius: 6px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}

.avtory {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas: ".";
}
@media (min-width: 1310px) {
  .avtory {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-template-areas: ". . .";
  }
}
@media screen and (max-width: 990px) {
  .avtory {
    grid-template-columns: 1fr;
    gap: 10px 10px;
    grid-template-areas: ".";
    text-align: left;
  }
}
.avtory__item {
  display: block;
  backdrop-filter: blur(16px) saturate(180%);
  width: 100%;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  padding: 10px;
  border-radius: 3px;
  font-size: 14px;
}

.news {
  border-radius: 12px;
  overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  background-color: #FFFDFE;
  border-radius: 12px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  position: relative;
  z-index: 200;
  align-items: stretch;
  flex-wrap: wrap;
  /*&__content { 
      padding: 0 20px;
  }*/
}
@media screen and (max-width: 990px) {
  .news {
    border: none !important;
    box-shadow: 0;
    border-radius: 0;
    background: #ffffff;
  }
}
.news__header {
  width: 100%;
}
.news__header:hover .news__header-heading {
  color: #6C6242;
}
.news__header:hover .news__header-image::before {
  opacity: 1;
}
.news__header-image {
  width: 100%;
  aspect-ratio: 409/309;
  background: center no-repeat;
  background-size: cover;
  display: block;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 990px) {
  .news__header-image {
    height: auto;
    aspect-ratio: 409/309;
  }
}
.news__header-image--page {
  height: 820px;
}
.news__header-image::before {
  background: rgba(35, 31, 32, 0.2);
  transition: 0.2s;
  opacity: 0;
}
.news__header-down {
  padding: 20px;
  width: calc(100% - 40px);
  margin-top: -20px;
  margin-left: 20px;
  padding-bottom: 20px;
  position: relative;
  border-radius: 6px 6px 0 0;
  background-color: #ffffff;
  background-color: #FFFDFE;
}
@media (min-width: 1310px) {
  .news__header-down {
    padding: 40px;
    width: calc(100% - 80px);
    margin-top: -40px;
    margin-left: 40px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 990px) {
  .news__header-down {
    padding: 20px;
    width: calc(100% - 40px);
    margin-left: 20px;
    background-color: #ffffff;
  }
}
.news__header-down--new {
  border-radius: 0 6px 0 0;
}
.news__header-label {
  position: absolute;
  top: -30px;
  left: 0;
  background: #231F20;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  height: 30px;
  padding: 0 10px;
}
@media screen and (max-width: 990px) {
  .news__header-label {
    font-size: 10px;
  }
}
.news__header-heading {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: underline;
}
@media screen and (max-width: 990px) {
  .news__header-heading {
    font-size: 18px;
  }
}
.news__header-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news__article {
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  width: calc(50% - 15px);
}
@media (min-width: 1310px) {
  .news__article {
    margin-left: 20px;
    margin-top: 20px;
    width: calc(50% - 30px);
  }
}
@media screen and (max-width: 990px) {
  .news__article {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.news__article:hover .news__article-heading {
  color: #6C6242;
}
.news__article:hover .news__article-image::before {
  opacity: 1;
}
.news__article-image {
  width: 100%;
  aspect-ratio: 409/309;
  background: center no-repeat;
  background-size: cover;
  display: block;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
@media screen and (max-width: 990px) {
  .news__article-image {
    height: auto;
    aspect-ratio: 409/309;
  }
}
.news__article-image--page {
  aspect-ratio: 409/309;
}
.news__article-image::before {
  background: rgba(35, 31, 32, 0.2);
  transition: 0.2s;
  opacity: 0;
}
.news__article-down {
  background: #ffffff;
  background-color: #FFFDFE;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  padding: 20px;
  width: calc(100% - 20px);
  margin-top: -20px;
  margin-left: 10px;
}
@media (min-width: 1310px) {
  .news__article-down {
    padding: 20px;
    width: calc(100% - 40px);
    margin-top: -20px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 990px) {
  .news__article-down {
    background: #ffffff;
    padding: 20px;
    width: calc(100% - 40px);
    margin-top: -20px;
    margin-left: 20px;
  }
}
.news__article-label {
  position: absolute;
  top: -30px;
  left: 0;
  background: #231F20;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  height: 30px;
  padding: 0 10px;
}
.news__article-heading {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: underline;
}
@media screen and (max-width: 990px) {
  .news__article-heading {
    font-size: 16px;
  }
}
.news__article-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news--arhiv {
  background: none;
  backdrop-filter: none;
  padding: 0;
  box-shadow: none;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 20px;
}
@media screen and (max-width: 990px) {
  .news--arhiv {
    margin-top: 10px;
  }
}
.news--arhiv .news__article {
  width: calc(33.3333333333% - 13px);
}
@media (min-width: 1310px) {
  .news--arhiv .news__article {
    width: calc(33.3333333333% - 26px);
  }
}
@media screen and (max-width: 990px) {
  .news--arhiv .news__article {
    width: 100%;
  }
}
.news--arhiv .news__article-image {
  aspect-ratio: 409/309;
}
@media screen and (max-width: 990px) {
  .news--arhiv .news__article-image {
    height: auto;
    aspect-ratio: 409/309;
  }
}

.telegram {
  padding: 0 13px;
  width: 100%;
  margin-bottom: 13px;
}
@media screen and (max-width: 990px) {
  .telegram {
    padding: 0;
  }
}
.telegram__button {
  text-align: center;
  width: 100%;
  height: 30px;
  border-radius: 8px;
  background-color: #1D98DC;
  transition: 0.2s;
  font-size: 12px;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-weight: bold;
}
.telegram__button::before {
  width: 30px;
  height: 30px;
  background: url(../images/icons/telegram.png) center no-repeat;
  background-size: 15px;
  margin-right: 2px;
  content: "";
}
.telegram__button:hover {
  background-color: #0E8ED4;
}

.vkb {
  padding: 0 13px;
  width: 100%;
  margin-bottom: 13px;
  display: none;
}
@media screen and (max-width: 990px) {
  .vkb {
    display: flex;
    padding: 0;
  }
}
.vkb__button {
  text-align: center;
  width: 100%;
  height: 30px;
  border-radius: 8px;
  background-color: #5281B8;
  transition: 0.2s;
  font-size: 12px;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-weight: bold;
}
.vkb__button::before {
  width: 30px;
  height: 30px;
  background: url(../images/icons/vk.png) center no-repeat;
  background-size: 15px;
  margin-right: 2px;
  content: "";
}
.vkb__button:hover {
  background-color: #759AC6;
}

.msearch {
  position: fixed;
  top: 60px;
  border-top: 1px #EDEDED solid;
  border-bottom: 1px #EDEDED solid;
  background: #ffffff;
  z-index: 999;
  width: 100%;
  padding: 0 10px;
  padding-bottom: 10px;
  display: none;
}
@media screen and (max-width: 990px) {
  .msearch {
    display: none;
  }
  .msearch--show {
    display: block;
  }
}
.msearch .head__form {
  width: 100%;
}

.mright {
  position: fixed;
  top: 60px;
  border-top: 1px #EDEDED solid;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 999;
  background: #ffffff;
  overflow-y: scroll;
  display: none;
}
@media screen and (max-width: 990px) {
  .mright {
    display: none;
  }
  .mright--show {
    display: block;
  }
}

.block {
  padding: 10px;
  padding-top: 20px;
  width: 100%;
  margin-bottom: 10px;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-radius: 12px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .block {
    padding: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 990px) {
  .block {
    border-radius: 0;
    margin-bottom: 0;
    background: #ffffff;
    backdrop-filter: none;
    box-shadow: none;
    border-right: 0;
    border-left: 0;
  }
  .block #vk_groups {
    display: none;
  }
}
.block:nth-last-child(1) {
  margin-bottom: 0;
}
.block__heading {
  display: block;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.block__heading::before {
  background-color: rgba(0, 0, 0, 0.5);
  width: 3px;
  height: 16px;
  content: "";
  position: absolute;
  top: 0;
  left: -11px;
}
@media (min-width: 1310px) {
  .block__heading::before {
    left: -21px;
  }
}
.block__heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px;
  width: calc(100% + 20px);
  height: 1px;
  background: #EDEDED;
}
@media (min-width: 1310px) {
  .block__heading::after {
    width: calc(100% + 40px);
    left: -20px;
  }
}
.block__image {
  width: 100%;
  height: 250px;
  background: center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: block;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.block__image:hover::after {
  opacity: 1;
}
.block__image::before {
  background: linear-gradient(to bottom, rgba(35, 31, 32, 0.6), transparent, rgba(35, 31, 32, 0.9));
}
.block__image::after {
  background: rgba(35, 31, 32, 0.2);
  transition: 0.2s;
  opacity: 0;
}
.block__image-heading {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  display: block;
  padding: 20px;
  font-weight: bold;
  color: #ffffff;
}
.block__image-date {
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  color: #ffffff;
}
.block__list {
  margin-top: 20px;
}
@media screen and (max-width: 990px) {
  .block__list {
    margin-top: 10px;
  }
}
.block__list-item {
  align-items: stretch;
  border-bottom: 1px #EDEDED solid;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}
@media (min-width: 1310px) {
  .block__list-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 990px) {
  .block__list-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
.block__list-item:nth-last-child(1) {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.block__list-item:hover .block__list-heading {
  color: #6C6242;
}
.block__list-item:hover .block__list-image::before {
  opacity: 1;
}
.block__list-heading {
  font-weight: bold;
  transition: 0.2s;
  text-decoration: underline;
}
.block__list-date {
  font-size: 11px;
  margin-bottom: 5px;
}
.block__list-image {
  width: 80px;
  min-width: 80px;
  height: 80px;
  background: center no-repeat;
  background-size: cover;
  margin-right: 10px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.block__list-image::before {
  background: rgba(35, 31, 32, 0.2);
  transition: 0.2s;
  opacity: 0;
}
.block__calendar {
  padding: 10px;
  border: 1px #EDEDED solid;
  position: relative;
  border-radius: 6px;
  margin-bottom: 20px;
}
@media (min-width: 1310px) {
  .block__calendar {
    padding: 20px;
  }
}
.block__calendar:nth-last-child(1) {
  margin-bottom: 0;
}
.block__calendar-date {
  background: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  height: 20px;
  padding: 0 10px;
  top: -10px;
  left: 10px;
  line-height: 1;
}
.block__calendar-content {
  font-size: 14px;
  line-height: 1.1;
}
.block__calendar-content img {
  width: 100%;
  object-fit: content;
  border-radius: 3px;
  margin-bottom: 10px;
}

.arhiv {
  border-radius: 12px;
  padding-bottom: 0;
}
.arhiv__item {
  width: calc(25% - 15px);
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 10px;
  display: block;
  overflow: hidden;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .arhiv__item {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
  .arhiv__item:nth-child(2n) {
    margin-right: 0 !important;
  }
}
@media (min-width: 1310px) {
  .arhiv__item {
    padding: 20px;
  }
}
.arhiv__item:nth-child(4n) {
  margin-right: 0;
}
@media screen and (max-width: 990px) {
  .arhiv__item:nth-child(4n) {
    margin-right: 20px;
  }
}
.arhiv__item:hover .arhiv__name {
  color: #6C6242;
}
.arhiv__image {
  border-radius: 3px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 650/900;
}
.arhiv__name {
  display: block;
  margin-top: 5px;
  transition: 0.2s;
  text-align: center;
}
@media (min-width: 1310px) {
  .arhiv__name {
    margin-top: 10px;
  }
}
@media screen and (max-width: 990px) {
  .arhiv__name {
    margin-top: 10px;
  }
}
.arhiv__year-name {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 990px) {
  .arhiv__year-name {
    margin-top: 10px;
  }
  .arhiv__year-name--padding {
    padding: 0 10px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.arhiv__year-content {
  flex-wrap: wrap;
  align-items: stretch;
}

.year {
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #ffffff;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  color: #ffffff;
  padding: 20px 0;
  padding-bottom: 10px;
}
@media screen and (max-width: 990px) {
  .year {
    padding: 10px 0;
    padding-bottom: 0;
  }
}
.year__center {
  flex-wrap: wrap;
  padding: 0 20px;
}
@media (min-width: 1310px) {
  .year__center {
    padding: 0;
  }
}
@media screen and (max-width: 990px) {
  .year__center {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 990px) {
  .year__center {
    padding: 0 10px;
  }
}
.year__item {
  display: block;
  padding: 10px 0;
  width: calc(20% - 8px);
  text-align: center;
  line-height: 1;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.2s;
  font-size: 16px;
  border-radius: 3px;
}
@media (min-width: 1310px) {
  .year__item {
    width: 116px;
  }
}
@media screen and (max-width: 990px) {
  .year__item {
    width: calc(33.3333333333% - 7px);
    font-size: 14px;
  }
  .year__item:nth-child(3n) {
    margin-right: 0 !important;
  }
}
.year__item:nth-child(5n) {
  margin-right: 0;
}
@media (min-width: 1310px) {
  .year__item:nth-child(5n) {
    margin-right: inherit;
  }
}
@media screen and (max-width: 990px) {
  .year__item:nth-child(5n) {
    margin-right: 10px;
  }
}
@media (min-width: 1310px) {
  .year__item:nth-child(10n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 990px) {
  .year__item:nth-child(10n) {
    margin-right: 10px;
  }
}
.year__item:hover {
  border-color: #655B37;
  color: #F1DC97;
  background: rgba(0, 0, 0, 0.1);
  border: 1px rgba(255, 255, 255, 0.5) solid;
}
.year__item--active {
  color: #F1DC97;
  border-color: #F1DC97;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
}
.year__item--active:hover {
  border-color: #F1DC97;
  color: #F1DC97;
}

.article {
  width: 100%;
}
@media screen and (max-width: 990px) {
  .article {
    padding: 0;
  }
}
.article__main {
  flex-wrap: wrap;
  width: 100%;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-radius: 12px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
  margin-top: 20px;
}
@media screen and (max-width: 990px) {
  .article__main {
    border-radius: 0;
    border: 0;
    backdrop-filter: none;
    box-shadow: none;
    margin-top: 0;
    box-shadow: none;
  }
}
.article__main .news__article {
  width: calc(33.3333333333% - 13px);
}
@media (min-width: 1310px) {
  .article__main .news__article {
    width: calc(33.3333333333% - 26px);
  }
}
@media screen and (max-width: 990px) {
  .article__main .news__article {
    width: calc(100% - 20px);
  }
}
.article__main .news__article-image {
  aspect-ratio: 409/309;
}
@media screen and (max-width: 990px) {
  .article__main .news__article-image {
    height: auto;
    aspect-ratio: 409/309;
  }
}
.article__image {
  width: 100%;
  aspect-ratio: 409/309;
  max-height: 60vh;
  background: center no-repeat;
  background-size: cover;
  border-radius: 6px 6px 0 0;
  position: relative;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #EDEDED;
}
@media screen and (max-width: 990px) {
  .article__image {
    border: 0;
    border-radius: 0;
    height: auto;
    aspect-ratio: 3/4;
  }
}
.article__image-description {
  font-size: 16px;
  transition: 0.2s;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 30px;
  padding-left: 240px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
}
@media screen and (max-width: 990px) {
  .article__image-description {
    padding: 20px;
  }
}
.article__image-name {
  text-shadow: 0px 0 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 0 40px;
}
@media (min-width: 1310px) {
  .article__image-name {
    padding: 0 100px;
  }
}
@media screen and (max-width: 990px) {
  .article__image-name {
    padding: 0 10px;
  }
}
.article__ul {
  list-style: none;
  position: absolute;
  top: 20px;
  left: 20px;
}
@media screen and (max-width: 990px) {
  .article__ul {
    top: 10px;
    left: 10px;
    flex-wrap: wrap;
  }
}
.article__li {
  padding: 8px 15px;
  font-size: 12px;
  margin-right: 10px;
  backdrop-filter: blur(16px) saturate(180%);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  transition: 0.2s;
  display: block;
}
@media screen and (max-width: 990px) {
  .article__li {
    margin-right: 5px;
    font-size: 11px;
    margin-bottom: 5px;
  }
}
.article__li--date {
  cursor: default;
}
.article__li strong {
  transition: 0.2s;
}
.article__li--author {
  cursor: pointer;
}
.article__li--author:hover strong {
  color: #6B6141;
}
.article__li--tag {
  cursor: pointer;
}
.article__li--tag:hover {
  color: #6B6141;
}
.article__li:nth-last-child(1) {
  margin-right: 0;
}
@media screen and (max-width: 990px) {
  .article__li:nth-last-child(1) {
    margin-bottom: 0;
  }
}
.article__album {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 200px;
  aspect-ratio: 650/900;
  background: #8C8C8E center no-repeat;
  background-size: cover;
  border-radius: 3px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  display: block;
  cursor: pointer;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .article__album {
    display: none;
  }
}
.article__top {
  margin-left: 220px;
}
@media screen and (max-width: 990px) {
  .article__top {
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }
}
.article__name {
  font-size: 26px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 30px;
  transition: 0.2s;
  text-decoration: underline;
}
@media screen and (max-width: 990px) {
  .article__name {
    font-size: 20px;
  }
}
.article__nomer {
  cursor: pointer;
  display: inline-block;
  color: #6B6142;
}
.article__pod {
  background: #6B6142;
  color: #ffffff;
  text-transform: uppercase;
  padding: 8px 15px;
  border-radius: 50px;
  font-size: 10px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}
.article__pod * {
  position: relative;
  z-index: 100;
}
.article__pod::after {
  margin-left: -30px;
  content: " ";
  width: 30px;
  height: 300px;
  background: #fff;
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
  position: absolute;
  left: -40px;
  top: -150px;
  top-animation-timing-function: ease-in-out;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}
.article__down {
  width: 100%;
  padding: 60px 20px;
  width: 100%;
  margin-bottom: 20px;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-radius: 0 0 6px 6px;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .article__down {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 990px) {
  .article__down {
    border: 0;
    border-bottom: 1px solid #EDEDED;
    padding: 20px 10px;
    border-radius: 0;
    margin-bottom: 20px;
    text-align: center;
    background: #ffffff;
    box-shadow: none;
  }
}
.article__lock {
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  border-radius: 6px;
  padding: 30px;
  margin-top: 20px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .article__lock {
    padding: 60px;
  }
}
@media screen and (max-width: 990px) {
  .article__lock {
    padding: 20px;
    border-radius: 0;
  }
}
.article__lock-span {
  font-size: 20px;
  margin-bottom: 30px;
}
@media screen and (max-width: 990px) {
  .article__lock-span {
    font-size: 19px;
  }
}
.article__lock-span .material-symbols-rounded {
  display: block;
  margin-right: 20px;
  cursor: default;
}
.article__lock-txt a {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 990px) {
  .article .text {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.red {
  flex-wrap: wrap;
  align-items: stretch;
}
.red__item {
  width: calc(50% - 5px);
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  backdrop-filter: blur(16px) saturate(180%);
  background: none;
  padding: 20px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1310px) {
  .red__item {
    margin-right: 20px;
    margin-bottom: 20px;
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 990px) {
  .red__item {
    width: 100%;
    margin-right: 0;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.red__item:nth-child(2n) {
  margin-right: 0;
}
.red__item-block:nth-last-child(1) {
  margin-left: 20px;
}
@media screen and (max-width: 990px) {
  .red__item-block:nth-last-child(1) {
    margin-left: 0;
    margin-top: 10px;
  }
}
.red__item-image {
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
  width: 255px;
  height: 355px;
}
@media screen and (max-width: 990px) {
  .red__item-image {
    width: 100%;
    aspect-ratio: 3/4;
    height: auto;
  }
}
.red__item-job {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.red__item-name {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  transition: 0.2s;
  text-decoration: underline;
  margin-bottom: 20px;
}
.red__item-description {
  display: block;
}

.text {
  font-size: 19px;
  line-height: 1.5;
  text-align: justify;
}
@media screen and (max-width: 990px) {
  .text {
    font-size: 17px;
    line-height: 1.3;
  }
}
.text img {
  width: 100% !important;
  border-radius: 6px;
  object-fit: cover;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .text img {
    object-fit: contain;
    height: auto !important;
    box-shadow: none;
  }
}
.text p {
  margin-bottom: 30px;
}
.text p:nth-last-child(1) {
  margin-bottom: 0;
}
.text a {
  text-decoration: underline;
  transition: 0.2s;
}
.text a:hover {
  color: #6B6142;
}

.paginator {
  overflow: hidden;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width: 990px) {
  .paginator {
    margin-top: 20px;
  }
}
.paginator__icon {
  width: 30px;
  height: 30px;
  font-size: 24px;
  transition: 0.2s;
  margin-left: 5px;
  padding: 20px;
  backdrop-filter: blur(16px) saturate(180%);
  background: #FFFDFE;
  border-radius: 3px;
  cursor: pointer;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .paginator__icon {
    background: #ffffff;
    width: 30px;
    height: 30px;
    padding: 10px;
  }
}
.paginator__icon:not(.paginator__icon--disable):hover {
  color: #6B6142;
}
.paginator__icon--disable {
  color: #C3CAD2;
  cursor: default;
}
.paginator__icon--disable .material-symbols-rounded {
  cursor: default;
}
.paginator__item {
  width: 30px;
  height: 30px;
  transition: 0.2s;
  margin-right: 5px;
  padding: 20px;
  backdrop-filter: blur(16px) saturate(180%);
  background: #FFFDFE;
  border-radius: 3px;
  cursor: pointer;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  box-shadow: -20px -20px 100px rgba(0, 0, 0, 0.05), 20px 20px 100px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 990px) {
  .paginator__item {
    background: #ffffff;
    width: 30px;
    padding: 10px;
    height: 30px;
  }
}
.paginator__item:nth-child(1) {
  margin-left: 5px;
}
.paginator__item:nth-last-child(1) {
  margin-right: 0;
}
.paginator__item:not(.paginator__item--active):hover {
  color: #6B6142;
}
.paginator__item--active {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}

#scrollToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  transition: opacity 0.2s;
  width: 50px;
  height: 50px;
  color: #ffffff;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.7);
}
@media (min-width: 1310px) {
  #scrollToTop {
    bottom: 20px;
    right: 20px;
  }
}
@media screen and (max-width: 990px) {
  #scrollToTop {
    width: 45px;
    height: 45px;
    bottom: 5px;
    right: 5px;
  }
}
#scrollToTop:hover {
  background-color: rgba(0, 0, 0, 0.8);
}